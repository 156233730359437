import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { CloseIcon, TimerIcon } from "../comman/Icon";
import sponsoredImg from "../assets/images/sponsoredImg.png";
import questionImg from "../assets/images/questionImg.png";
import AxiosRequest from "../AxiosRequest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import NoData from "../components/NoData";
import OverlayLoader from "../components/OverlayLoader";
import CryptoJS from "crypto-js";
import correct_ans_click from "../assets/audio/correct_ans_click.mp3";
import wrong_ans_click_new from "../assets/audio/wrong_ans_click_new.mp3";
import Logo from "../assets/images/logoNew.png";
import confirmPopup from "../utils/confirmPopup";

const initValue = { data: [], loading: false };


const DisplayQueImage = ({queData, setIsImageLoaded}) => {

  const [isLoading, setIsLoading] = useState(true);
	const isGoogleDriveImage = (url) => {
		return url.includes('drive.google.com');
	};
	
	const getGoogleDriveImageSrc = (url) => {
		const fileId = url.split('/d/')[1].split('/')[0];
		return `https://drive.google.com/uc?export=view&id=${fileId}`;
	};

  useEffect(() => {
    setIsLoading(true)
  }, [queData?.url])

	return queData?.type === 'VIS' && queData?.url !== '' ?
						 
	<div className="row">
		<div className="col-md-12">
      {isLoading && <div className="d-flex align-items-center justify-content-center py-5">
          <Spinner
            variant="light"
            style={{ height: "30px", width: "30px" }}
          />
        </div>}
			{isGoogleDriveImage(queData?.url) ?  
      <img
        id="image-load"
        src={getGoogleDriveImageSrc(queData?.url)}
        alt="question"
        className="img-fluid img-landscape"
        style={{ display: `${isLoading ? 'none' : 'inline'}`, height: "200px" }}
        onLoad={() => {
          setIsImageLoaded(true)
          setIsLoading(false)
        }}
    />  :    
      <img 
        id="image-load"
        className="img-fluid img-landscape"
        src={queData?.url} 
        alt={queData?.correctAnswer}
        style={{ display: `${isLoading ? 'none' : 'inline'}`, height: "200px" }}
        onLoad={() => {  
          setIsImageLoaded(true)
          setIsLoading(false); 
        }}/>
      }
		</div>
	</div>

: null
}

const RoundQuestion = ({ step, setStep }) => {
  const reff = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(initValue);
  const [currantQuestion, setCurrantQuestion] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [roundStatus, setRoundStatus] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const _browser = useMemo(() => {
    const userAgent = navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    const isFirefox = /firefox/i.test(userAgent);
    const isMobile = /mobile/i.test(userAgent);
    return { isSafari, isFirefox, isMobile }
  }, [navigator.userAgent])

  useEffect(() => {
    if (
      currantQuestion === 0 &&
      !questions.loading &&
      questions.data.length > 0
    ) {
      setTimer(questions?.data[0]?.time);
    }
  }, [questions, currantQuestion]);

  const handleSubmitAnswer = async (allQuestionsStatus = []) => {
    try {
      setIsLoading(true);

      const onlyAttempedQuestions = allQuestionsStatus.filter(
        (que) => que.isTrue !== "skip"
      );
      const correctAns = allQuestionsStatus.filter(
        (que) => que.isTrue === true
      );

      const score = (
        ((correctAns.length || 0) / (allQuestionsStatus.length || 0)) *
        100
      )?.toFixed(0);
      const timeTaken = onlyAttempedQuestions.reduce(
        (acc, curr) => acc + curr.timeTaken,
        0
      );
      const successRateOfAttempted = (
        ((correctAns.length || 0) / (onlyAttempedQuestions.length || 0)) *
        100
      )?.toFixed(0);

      const result =
        Number(score) >= Number(location?.state?.passingMarks)
          ? "pass"
          : "fail";

      const payload = {
        quizId: location?.state?.quizId,
        roundId: location?.state?.roundId,
        score: score || 0,
        timeTaken: timeTaken || 0,
        successRateOfAttempted:
          successRateOfAttempted === "NaN" ? 0 : successRateOfAttempted,
        result,
      };

      const { data } = await AxiosRequest.post(`result/save`, { ...payload });

      toast.success(data.message);
      navigate("/result", {
        state: {
          totalQuestions: allQuestionsStatus.length,
          correctAns: correctAns.length,
          score: score || 0,
          quizTitle: location?.state?.quizTitle,
          result,
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || err?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getQuestions = async () => {
    try {
      reff.current = true;
      setQuestions((prev) => ({ ...prev, loading: true }));
      const { data } = await AxiosRequest.post(`question/list`, {
        roundId: location?.state?.roundId,
      });
      const imageLoaded = data.data[0]?.url ? false : true;

      setIsImageLoaded(imageLoaded);
      setQuestions((prev) => ({
        data: data.data,
        loading: false,
      }));
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || err?.message || "Something went wrong"
      );
      setQuestions(initValue);
    }
  };

  useEffect(() => {
    if (!reff?.current && step === 3 && location?.state?.isInFlow)
      getQuestions();
  }, [step]);

  useEffect(() => {
    let interval;
    if (isImageLoaded) {
      interval = setInterval(() => {
        if (timer <= 1) {
          if (!questions.loading && questions.data.length > 0) {
            if (currantQuestion <= questions.data.length - 1) {
              nextQuestion({ currantAnsStatus: "skip" });
            } else handleSubmitAnswer(roundStatus);
          }
        } else {
          setTimer((prevTimer) => prevTimer - 1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, isImageLoaded]);

  const nextQuestion = ({ currantAnsStatus = "skip" }) => {
    if (!questions.loading && questions.data.length > 0) {
      const _id = questions.data[currantQuestion]?._id;
      const timeTaken = questions.data[currantQuestion]?.time - timer;

      const newRoundStatus = { _id, isTrue: currantAnsStatus, timeTaken };
      newRoundStatus !== null &&
        setRoundStatus((prev) => [...prev, newRoundStatus]);

      if (currantQuestion < questions.data.length - 1) {
        const imageLoaded = questions.data[currantQuestion + 1]?.url
          ? false
          : true;

        setIsImageLoaded(imageLoaded);

        setCurrantQuestion(currantQuestion + 1);
        setTimer(questions.data[currantQuestion + 1]?.time);
        setIsAnswered(false);
      } else {
        handleSubmitAnswer([...roundStatus, newRoundStatus]);
      }
    }
  };

  const handleCloseQuiz = async () => {
    if (!questions.loading && questions.data.length === 0) {
      navigate("/");
    } else {

      if (document.fullscreenElement && _browser.isMobile && !_browser.isSafari && !_browser.isFirefox) {
        try {
          if (document.exitFullscreen) {
            await document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            await document.webkitExitFullscreen();
          }
        } catch (error) {
          console.error("Error exiting fullscreen mode", error);
        }
      }
      confirmPopup({
        title: "Are you sure?",
        text: "You want to quit this quiz!",
        icon: "warning",
        confirmButtonText: "Yes",
        action: async () => {

          if (width <= 900 && _browser.isMobile && !_browser.isSafari && !_browser.isFirefox) {
            const element = document.querySelector("#question-id");
            if (element.requestFullscreen) {
              await element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
              await element.webkitRequestFullScreen();
            }
            await window.screen.orientation.lock("portrait-primary");
          }

          navigate("/")
        },
        cancleAction: () => landscape(),
        // target: document.querySelector('#question-id')
      });

    }
  };

  const _correctAnswer = useMemo(() => {
    if (!questions.loading && questions.data.length > 0) {
      const res = CryptoJS.AES.decrypt(
        questions.data[currantQuestion]?.correctAnswer,
        process.env.REACT_APP_ENCRYPT_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
      return res;
    }
  }, [questions, currantQuestion]);

  const landscape = async () => {
    if (width <= 900 && _browser.isMobile && !_browser.isSafari && !_browser.isFirefox) {
      const element = document.querySelector("#question-id");

      try {
        if (element.requestFullscreen) {
          await element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          // Note the capital S in Screen
          await element.webkitRequestFullScreen();
        }

        await window.screen.orientation.lock("landscape-primary");
      } catch (error) {
        console.error("Could not lock screen to landscape", error);
      }
    }
  };

  useEffect(() => {
    landscape();

    return () => {
      const exitFullscreen = async () => {
        if (document.fullscreenElement) {
          try {
            if (document.exitFullscreen) {
              await document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
              await document.webkitExitFullscreen();
            }
          } catch (error) {
            console.error("Error exiting fullscreen mode", error);
          }
        }
      };

      const portrait = async () => {
        try {
          await window.screen.orientation.unlock();
          await window.screen.orientation.lock("portrait-primary");
        } catch (error) {
          console.error("Could not lock screen to portrait", error);
        }
      };

      if (_browser.isMobile && !_browser.isSafari && !_browser.isFirefox) {
        exitFullscreen().then(portrait);
      }
    };
  }, []);

  return (
    <>
      <Container
        className="question-page"
        id={`${width <= 900 ? "question-id" : ""}`}
      // id={`question-id`}
      >
        <Row className="mx-0 justify-content-between my-md-3 mt5px">
          <div className="col-auto">
            <span role="button" onClick={handleCloseQuiz}>
              <CloseIcon />
            </span>
          </div>
          <div className="col-auto">
            <div className="fontwhite14 mb-1">Sponsored by</div>
            <img src={location?.state?.sponsor?.imageUrl ? location?.state?.sponsor?.imageUrl: Logo} className="sponsoredImg" />
          </div>
        </Row>

        <div className="d-flex flex-column align-items-center justify-content-md-center text-center horScrollHide heightFull my-2">
          <div className="question-box w-100">
            <Row className="justify-content-center">
              {questions.loading ? (
                <div className="d-flex align-items-center justify-content-center py-5">
                  <Spinner
                    variant="light"
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              ) : questions.data.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center py-5">
                  <NoData className="opacity-75" />
                </div>
              ) : (
                <>
                  <Col xxl={7} xl={7} lg={8} sm={11}>
                    {questions.data[currantQuestion]?.url ? (
                      <>
                        <div className="question">
                          {/* <div className="questionText">
                            {questions.data[currantQuestion]?.question}
                          </div> */}
                          <DisplayQueImage setIsImageLoaded={setIsImageLoaded} queData={questions.data[currantQuestion]} />
                          {/* <img
                            id="image-load"
                            src={questions.data[currantQuestion]?.url}
                            alt="question"
                            className="img-fluid"
                            onLoad={() => setIsImageLoaded(true)}
                          /> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="question-circle  mx-auto mb-1 mb-md-4">
                          <p className="fontwhite24-light m-0">
                            {`${currantQuestion + 1}/${questions.data.length}`}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="questionTimer d-flex align-items-center">
                            <TimerIcon />
                            <span className="ms-2">{timer}s</span>
                          </div>
                        </div>
                        <div className="content-question pt-2">
                          <div className="ans1Line">
                            <div className="ans1">
                              <div className="inner">
                                <div className="bg">
                                  {questions.data[currantQuestion]?.question}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                  <Col xxl={7} xl={7} lg={8} sm={10} className="mt-1 mt-md-3 col-11">
                    <div className="d-flex justify-content-between">
                      {questions.data[currantQuestion]?.url ? (
                        <>
                          <div className="questionNumber">{`${currantQuestion + 1
                            } / ${questions.data.length}`}</div>
                          <div className="questionTimer d-flex align-items-center">
                            <TimerIcon />
                            <span className="ms-2">{timer}s</span>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="d-flex flex-wrap">
                      {questions.data[currantQuestion]?.options.map(
                        (option, i) => (
                          <Option
                            key={i}
                            option={option}
                            cls={i % 2 === 0 ? "lineLeft" : "lineRight"}
                            correctAnswer={_correctAnswer}
                            nextQuestion={nextQuestion}
                            isAnswered={isAnswered}
                            setIsAnswered={setIsAnswered}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            _browser={_browser}
                          />
                        )
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </Container>

      {isloading ? <OverlayLoader /> : null}
    </>
  );
};

const Option = ({
  option,
  cls = "",
  correctAnswer,
  nextQuestion,
  isAnswered,
  setIsAnswered,
  selectedOption,
  setSelectedOption,
  _browser
}) => {
  const [isTrue, setIsTrue] = useState("");
  const correctAnsAudio = useMemo(() => new Audio(correct_ans_click), []);
  const wrongAnsAudio = useMemo(() => new Audio(wrong_ans_click_new), []);
  useEffect(() => {
    correctAnsAudio.preload = 'auto';
    wrongAnsAudio.preload = 'auto';
    correctAnsAudio.load();
    wrongAnsAudio.load();
  }, []);

  const handleCorrectAnswer = () => {
    let currantAnsStatus = "skip";
    if (!isAnswered) {
      setIsAnswered(true);
      setSelectedOption(option);
      if (option === correctAnswer) {
        correctAnsAudio.play();
        currantAnsStatus = true;
        setIsTrue(true);
      } else {
        wrongAnsAudio.play();
        currantAnsStatus = false;
        setIsTrue(false);
      }

      setTimeout(() => {
        nextQuestion({ currantAnsStatus });
        setIsTrue("");
      }, 1000);
    }
  };

  const status = useMemo(() => {
    if (isTrue === true) return "ansRight";
    if (isTrue === false) return "ansWrong";
    if (
      isAnswered &&
      option === correctAnswer &&
      selectedOption !== correctAnswer
    ) {
      return "ansRight";
    }
    return "";
  }, [isTrue, option, correctAnswer, selectedOption, isAnswered]);

  return (
    <div
      role="button"
      onClick={handleCorrectAnswer}
      className={`
      ${((_browser.isMobile && (_browser.isSafari || _browser.isFirefox))) ? `col-12` : `${cls} col-6 col-md-6`} _line mt-3 ans1Line`}
    >
      <div className="ans1">
        <div className="inner">
          <div className={`bg ${status}`}>{option}</div>
        </div>
      </div>
    </div>
  );
};

export default RoundQuestion;
